<template>
  <div>
    <div v-if="isLoading">
      <OtherLoading />
    </div>
    <div v-else class="form-box">
      <b-row class="no-gutters">
        <b-col>
          <h4 class="font-weight-bold header-tablepage">IMAGE MAP (LINE)</h4>
        </b-col>
      </b-row>
      <div class="container-box min-vh-100 mt-3">
        <div class="bg-white border-red px-4 px-sm-5 py-4">
          <b-row>
            <b-col md="5">
              <div
                class="panel-bg-file-img mr-1 mb-1"
                v-bind:style="{
                  backgroundImage: 'url(' + form.image_url + ')',
                }"
              >
                <img
                  src="@/assets/icons/loading.svg"
                  class="loading"
                  alt="loading"
                  v-if="isLoadingImage"
                />
                <font-awesome-icon
                  icon="times-circle"
                  class="text-secondary delete-icon pointer"
                  v-if="form.image_url"
                  @click.prevent="onDeleteImage"
                />
              </div>
            </b-col>
            <b-col md="7">
              <UploadFile
                textFloat="Cover Image"
                placeholder="กรุณาเลือกไฟล์เพื่ออัพโหลด"
                format="image"
                name="thumbnail"
                :fileName="form.image_url"
                :accept="acceptType"
                text="*Please upload only file .png, .jpg size 1:1  and less than 10 MB"
                isRequired
                v-on:onFileChange="onImageChange"
                :v="$v.form.image_url"
                :isValidate="$v.form.image_url.$error"
              />
            </b-col>
          </b-row>
          <b-row class="mt-4">
            <b-col>
              <InputTextArea
                class=""
                type="text"
                textFloat="Text"
                placeholder=""
                name="text"
                rows="5"
                isRequired
                v-model="form.text"
                :isValidate="$v.form.text.$error"
                :v="$v.form.text"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <div class="font-option">Opions</div>
              <div class="mt-1">
                <b-table
                  class="table-list-imagemap"
                  responsive
                  striped
                  hover
                  :items="form.option"
                  :fields="fields"
                  :busy="isBusy"
                  show-empty
                  empty-text="ไม่พบข้อมูล"
                  ref="table"
                >
                  <template v-slot:table-busy>
                    <div class="text-center text-black my-2">
                      <b-spinner class="align-middle"></b-spinner>
                      <strong class="ml-2">Loading...</strong>
                    </div>
                  </template>
                  <template v-slot:cell(text)="data">
                    <InputText
                      v-if="data.item.isEdit"
                      textFloat=""
                      placeholder=""
                      type="text"
                      name="text"
                      v-model="data.item.text"
                      :isValidate="
                        $v.form.option.$each.$iter[data.index].text.$error
                      "
                    />
                    <span v-else>{{ data.item.text }}</span>
                  </template>
                  <template v-slot:cell(tag_name)="data">
                    <InputText
                      v-if="data.item.isEdit"
                      textFloat=""
                      placeholder=""
                      type="text"
                      name="tag_name"
                      v-model="data.item.tag_name"
                      :isValidate="
                        $v.form.option.$each.$iter[data.index].tag_name.$error
                      "
                    />
                    <span v-else>{{ data.item.tag_name }}</span>
                  </template>
                  <template v-slot:cell(sort_order)="data">
                    <InputText
                      v-if="data.item.isEdit"
                      textFloat=""
                      placeholder=""
                      type="text"
                      name="sortOrder"
                      v-model="data.item.sort_order"
                      :isValidate="
                        $v.form.option.$each.$iter[data.index].sort_order.$error
                      "
                    />
                    <span v-else>{{ data.item.sort_order }}</span>
                  </template>
                  <template v-slot:cell(id)="data">
                    <u
                      class="pointer"
                      v-if="!data.item.isEdit"
                      @click="onSetEditToRecord(data.item)"
                      >แก้ไข</u
                    >

                    <button
                      type="button"
                      class="btn btn-main btn-details-set ml-md-2 mr-2 text-uppercase"
                      v-if="data.item.isEdit"
                      @click="checkFormByRecord(data.item)"
                    >
                      <div class="">
                        <div class="my-auto mr-auto">บันทึก</div>
                      </div>
                    </button>
                    <div
                      class="pointer mt-1 text-underline"
                      v-if="data.item.isEdit"
                      @click="onUnsetEditToRecord(data.item)"
                    >
                      ยกเลิก
                    </div>
                  </template>
                </b-table>
              </div>
              <div v-if="$v.form.option.$error" class="text-error">
                กรุณากรอกข้อมูลให้ครบถ้วน
              </div>
            </b-col>
          </b-row>
        </div>
        <b-row class="mt-5">
          <b-col class="text-sm-right">
            <b-button
              @click.prevent="checkForm()"
              type="button"
              :disabled="isDisable"
              class="btn btn-main btn-details-set ml-md-2 text-uppercase"
            >
              SAVE
            </b-button>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import OtherLoading from "@/components/loading/OtherLoading";
import UploadFile from "@/components/inputs/UploadFile";
import { required, requiredIf } from "vuelidate/lib/validators";
import InputText from "@/components/inputs/InputText";
import InputTextArea from "@/components/inputs/InputTextArea";
export default {
  components: {
    OtherLoading,
    UploadFile,
    InputTextArea,
    InputText,
  },
  validations: {
    form: {
      text: { required },
      image_url: { required },
      option: {
        $each: {
          text: { required },
          tag_name: { required },
          sort_order: { required },
        },
      },
    },
  },
  data() {
    return {
      acceptType: "image/jpeg, image/png",
      isLoading: true,
      isLoadingImage: false,
      form: {
        text: "",
        image_url: "",
        option: [
          {
            text: "",
            tag_name: "",
            sort_order: 1,
          },
          {
            text: "",
            tag_name: "",
            sort_order: 2,
          },
          {
            text: "",
            tag_name: "",
            sort_order: 3,
          },
          {
            text: "",
            tag_name: "",
            sort_order: 4,
          },
          {
            text: "",
            tag_name: "",
            sort_order: 5,
          },
          {
            text: "",
            tag_name: "",
            sort_order: 6,
          },
          {
            text: "",
            tag_name: "",
            sort_order: 7,
          },
        ],
      },
      fields: [
        {
          key: "text",
          label: "ข้อความ",
          class: "",
        },
        {
          key: "tag_name",
          label: "ป้ายชื่อ",
          class: "",
        },
        {
          key: "sort_order",
          label: "ลำดับที่",
          class: "",
        },
        {
          key: "id",
          label: "",
          class: "",
        },
      ],
      isBusy: false,
      isDisable: false,
      id: 0,
    };
  },
  async created() {
    await this.getImageMap();
  },
  methods: {
    async getImageMap() {
      await this.$store.dispatch("getImageMap");
      const data = this.$store.state.automation.imageMap;
      if (data.result === 1) {
        this.form.image_url = data.detail.image_url;
        this.form.text = data.detail.text;
        this.form.option = data.detail.option;
        this.id = data.detail.id;
        this.form.option.forEach(function (element) {
          element.isEdit = false;
        });
        this.isLoading = false;
      } else {
        if (data.detail.id === 0) {
          this.isLoading = false;
          this.form.option.forEach(function (element) {
            element.isEdit = true;
          });
        }
      }
    },
    onImageChange(img) {
      this.isLoadingImage = true;
      this.isDisable = true;
      var reader = new FileReader();
      reader.readAsDataURL(img);
      reader.onload = async () => {
        this.images = await this.saveImagetoDb(reader.result);
        this.isLoadingImage = false;
        this.isDisable = false;
        this.form.image_url = this.images;
      };
    },
    saveImagetoDb: async function (img) {
      var form = {
        Base64String: img,
      };
      await this.$store.dispatch("ActionUploadImage", form);
      var data = await this.$store.state.moduleConnectAPI.stateUploadImage;
      if (data.result == 1) {
        return data.detail;
      }
    },
    onDeleteImage() {
      this.form.image_url = "";
    },
    onSetEditToRecord(val) {
      this.isLoading = true;
      val.isEdit = true;
      this.isLoading = false;
      this.$refs.table.refresh();
    },
    onUnsetEditToRecord(val) {
      this.isLoading = true;
      val.isEdit = false;
      this.isLoading = false;
      this.$refs.table.refresh();
    },
    checkFormByRecord(val) {
      this.isLoading = true;
      val.isEdit = false;
      this.isLoading = false;
      this.$refs.table.refresh();
    },
    async checkForm() {
      this.$v.form.$touch();
      if (this.$v.form.$error) {
        return;
      }
      this.isLoading = true;
      this.form.option.map((el) => {
        delete el.isEdit;
      });
      if (this.id === 0) {
        await this.$store.dispatch("createImageMap", this.form);
        const data = this.$store.state.automation.respCreateImageMap;
        if (data.result === 1) {
          this.$swal("Create Success!", {
            icon: "success",
          }).then(function () {
            window.location.href = "/automation/imagemap";
          });
        } else {
          this.isLoading = false;
          this.$swal(data.message, {
            icon: "warning",
          });
        }
      } else {
        this.form.id = this.id;
        await this.$store.dispatch("updateImageMap", this.form);
        const data = this.$store.state.automation.respUpdateImageMap;
        if (data.result === 1) {
          this.$swal("Update Success!", {
            icon: "success",
          }).then(function () {
            window.location.href = "/automation/imagemap";
          });
        } else {
          this.isLoading = false;
          this.$swal(data.message, {
            icon: "warning",
          });
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.panel-bg-file-img {
  width: 100%;
  padding-bottom: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  align-items: center;
  border: 2px dashed #bfbfbf;
  margin-bottom: 15px;
  position: relative;
}
.font-option {
  color: #000;
  font-weight: 600;
  font-size: 17px;
}
.text-underline {
  text-decoration: underline;
}
::v-deep thead {
  background-color: var(--primary-color) !important;
  color: var(--font-color);
}
.delete-icon {
  float: right;
  font-size: 20px;
}
</style>
